<template >
    <div>

        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">חבילות</span>
        </div>
        <v-container>
        <v-card style="background-color: white;">
            <div class="padding-responsive">
                <div class="padding-responsive">
                    <ProgressShow v-if="progressShow" v-model="progressShow"></ProgressShow>

                    <span style="font-size: 24px; color: #6A707E;">פרטי חבילות</span>
                    <div style="display: flex; align-items: center; direction: ltr; margin-left: 6%; margin-bottom: 20px;">
                        <v-btn color="#8066f0" dark @click="openCreateDIalog"
                            style="margin-left: 20px; margin-right: 20px; font-weight: 500; font-size: medium;">הוסף
                            חבילה</v-btn>
                    </div>
                    <v-row class="margin-responsive" :class="{ 'd-flex justify-space-around': plans.length > 2 }">
                        <v-col cols="auto" md="5" v-for="(item, index) in plans">

                            <div class="box_credit_buy_package"
                                style="width: 600px; max-width: 100%; min-height: 300px; background-color: white;">
                                <v-row>
                                    <v-col colls="12" sm="8">
                                        <div style="padding: 15px; display: flex; justify-content: center;">
                                            <span 
                                                style="font-size: large; color: #0d2c6d;">{{ item.name
                                                }}</span>
                                        </div>

                                        <div style="padding: 15px; display: flex; justify-content: center;">
                                            <span style="color: #888484;">{{ item.details
                                            }}</span>
                                        </div>
                                    </v-col>
                                    <v-col colls="12" sm="4">
                                        <div
                                            style="height: 250px; max-height: 70% !important;  border: 1px solid #8066f0; margin: 10px; ">
                                          <div style="display: flex; justify-content: center;">
                                            <v-avatar style="width: 80%; height: 70%; margin: 10px;" v-if="item.operating_company === 'partner'">
                                                <img src="@/assets/partner.png" contain alt="John">
                                            </v-avatar>
                                            <v-avatar style="width: 80%; height: 70%; margin: 10px;" v-if="item.operating_company === 'hotMobile'">
                                                <img src="@/assets/hot_mobile.png" contain alt="John">
                                            </v-avatar>
                                          </div>
                                            <div style="display: flex; justify-content: center; margin: 10px;">
                                                <span style="color: #8066f0; font-weight: 600;">{{ item.price }} ש"ח</span>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <div
                                    style="background-color: #edf2f7; display: flex; align-items: center; justify-content: space-around; width: 100%; height: 50px; position: absolute; bottom: 0 !important;">
                                    <v-tooltip bottom class="ml-2" v-if="item.show_to_user">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="green" dark v-bind="attrs" v-on="on">
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>מוצג ללקוח</span>
                                    </v-tooltip>
                                    <v-tooltip bottom class="ml-2" v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="red" dark v-bind="attrs" v-on="on">
                                                mdi-eye-off
                                            </v-icon>
                                        </template>
                                        <span>מוסתר ללקוח</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" color="#0d2c6d" medium
                                                class="me-3" @click="deletePlan(item)">
                                                mdi-delete-outline
                                            </v-icon>
                                        </template>
                                        <span>מחיקה</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" color="#0d2c6d" medium
                                                class="me-3" @click="openEditDialog(item)">
                                                mdi-square-edit-outline
                                            </v-icon>
                                        </template>
                                        <span>עריכה</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                </div>
            </div>
        </v-card>
    </v-container>
        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>

        <PlansAddEditDialog v-model="editPlanDialog" v-if="editPlanDialog" :planToEdit="planToEdit" :formTitle="formTitle"
            @planEdited="planEditedSaved" @planCreated="newPlanSaved" />
            
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";
import PlansAddEditDialog from '@/components/admin/plans/dialogs/PlansAddEditDialog'
import ProgressShow from '@/components/widgets/progressShow.vue'

export default {

    components: {
        SnackBar,
        PlansAddEditDialog,
        ProgressShow
    },
    data: () => ({
        overlay: false,
        editPlanDialog: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        progressShow: true,
        plans: []
    }),
    computed: {

    },
    methods: {
        async getPlans() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/plans/get_all_plans`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.plans = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        openEditDialog(item) {
            this.planToEdit = item;
            this.formTitle = "ערוך חבילה"
            this.editPlanDialog = true;
        },
        openCreateDIalog() {
            this.formTitle = "הוסף חבילה"
            this.planToEdit = {}
            this.editPlanDialog = true
        },
        closeDelete() {
            this.openMode = false;
        },
        planEditedSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.plans.findIndex(plan => plan._id === item._id);

            let g = Object.assign(this.plans[index], item);
        },
        newPlanSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            this.plans.push(item);

        },
        async deletePlan(item) {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const planJSON = JSON.stringify({ plan: item });
                let api = process.env.VUE_APP_BASE_URL + `/plans/delete_plan`;
                const res = await fetch(api, ApiServices.requestOptions("DELETE", planJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה במחיקת החבילה: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.showSnackBar("החבילה נמחקה בהצלחה!", "green");
                    // Use splice to remove the item at the specified index
                    const index = this.plans.findIndex(plan => plan._id === item._id);
                    this.plans.splice(index, 1);
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    created() {
        this.getPlans();
    },
}
</script>
<style >
.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.box_credit_buy_package {
    box-shadow: -2px 4px 8px 1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: -2px 4px 8px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: -2px 4px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: relative;
}


.padding-responsive {
    padding: 10px;
}

@media (min-width: 600px) {
    .padding-responsive {
        padding: 30px;
    }

    .margin-responsive {
        margin: 10px;
    }
}
</style>
    